<template>
  <div class="container">
    <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
      <a-breadcrumb-item>单位管理</a-breadcrumb-item>
      <a-breadcrumb-item>审核账号管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mainBox" style="padding: 24px">
      <a-tabs default-active-key="3" @change="changeTabs">
        <a-tab-pane key="3" tab="小票机审核">
          <div class="control_box">
            <Search-bar :inputs="inputs" @search="search" @reset="search" />
            <div class="control_box_bottom">
              <a-button type="primary" icon="plus" @click="showBatch()" style="margin-top: 10px">添加审核用户</a-button>
            </div>
          </div>
          <!-- 内容区域 -->
          <div class="show_box">
            <a-table :columns="columns" :data-source="tableData" :loading="loading"
              :rowKey="(tableData) => tableData.id" :pagination="pagination">
              <template slot="action" slot-scope="text, record">
                <a-button type="link" @click="() => edit(record)" >编辑</a-button>

                <a-button type="link" @click="() => remove(record.id)" class="e-space" >删除</a-button>

              </template>
              <template slot="status" slot-scope="text, record">
                <a-switch :checked="record.status == 1" :loading="disableLoading"
                  @change="() => changeStatus(record)" />
              </template>
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="1" tab="充值审核" force-render>
          <div class="control_box">
            <Search-bar :inputs="inputs" @search="search" @reset="search" />
            <div class="control_box_bottom">
              <a-button type="primary" icon="plus" @click="showBatch()" style="margin-top: 10px">添加审核用户</a-button>
            </div>
          </div>
          <!-- 内容区域 -->
          <div class="show_box">
            <a-table :columns="columns" :data-source="tableData" :loading="loading"
              :rowKey="(tableData) => tableData.id" :pagination="pagination">
              <template slot="action" slot-scope="text, record">
                <a-button type="link" @click="() => edit(record)" >编辑</a-button>

                <a-button type="link" @click="() => remove(record.id)" class="e-space" >删除</a-button>

              </template>
              <template slot="status" slot-scope="text, record">
                <a-switch :checked="record.status == 1" :loading="disableLoading"
                  @change="() => changeStatus(record)" />
              </template>
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="退餐审核">
          <div class="control_box">
            <Search-bar :inputs="inputs" @search="search" @reset="search" />
            <div class="control_box_bottom">
              <a-button type="primary" icon="plus" @click="showBatch()" style="margin-top: 10px">添加审核用户</a-button>
            </div>
          </div>
          <!-- 内容区域 -->
          <div class="show_box">
            <a-table :columns="columns" :data-source="tableData" :loading="loading"
              :rowKey="(tableData) => tableData.id" :pagination="pagination">
              <template slot="action" slot-scope="text, record">
                <a-button type="link" @click="() => edit(record)" >编辑</a-button>
                <a-button type="link" @click="() => remove(record.id)" class="e-space" >删除</a-button>
              </template>
              <template slot="status" slot-scope="text, record">
                <a-switch :checked="record.status == 1" :loading="disableLoading"
                  @change="() => changeStatus(record)" />
              </template>
            </a-table>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 新增/修改 -->
    <a-modal v-model="editModalVisible" :title="editModalTitle" :okText="editModalOkText" cancelText="取消" :width="560"
      @ok="preSave()">
      <a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :model="tmp"
        :rules="formRules">
        <a-form-model-item label="账号" prop="auditor">
          <a-input v-model="tmp.auditor" placeholder="请输入账户名称，账户为4位数字组成" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input-password v-model="tmp.password" placeholder="请输入审核密码，密码为6位数字组成" />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch :checked="tmp.status == 1" @change="() => changeTmpStatus()" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  auditAdmins,
  editAuditAdmin,
  addAuditAdmin,
  removeAuditAdmin,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
  },
  data () {
    return {
      pagination: false,
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [], //不分页的数据
      loading: false, //table加载状态
      // 表格的列配置
      columns: [
        {
          title: "用户名",
          key: "auditor",
          dataIndex: "auditor",
        },
        {
          title: "类型",
          key: "type",
          dataIndex: "type",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "状态",
          key: "is_valid",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //传给searchBar公共搜索组件的数据
      inputs: [
        {
          placeholder: "用户名",
          model: "auditor",
          width: 300,
        },
      ],

      //请求参数
      queryParam: {
        auditor: "",
      },
      type: 3,
      disableLoading: false,
      editModalVisible: false,
      editModalTitle: "新增审核用户",
      editModalOkText: "保存",
      tmp: {
        id: "",
        auditor: "",
        status: 1,
      },
      formRules: {
        auditor: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        auditor: val.input.auditor ? val.input.auditor : "",
        type: this.type,
      };
      this.getTableData();
    },
    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.type = this.type;
      auditAdmins(this.queryParam).then((res) => {
        if (res.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = res.data;
        this.loading = false;
      });
    },
    changeTabs (v) {
      this.type = v;
      this.getTableData();
    },
    changeStatus (row) {
      this.disableLoading = true;
      let p = {
        id: row.id,
        status: row.status == 1 ? 2 : 1,
        type: row.type,
        auditor: row.auditor,
      };
      editAuditAdmin(p)
        .then((res) => {})
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
            row.status = p.status;
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    //打开个性化批量充值导入模态框
    showBatch () {
      this.editModalVisible = true;
      this.editModalTitle = "添加审核用户";
      this.editModalOkText = "保存"
      this.resetTmp();
    },
    changeTmpStatus () {
      this.tmp.status = this.tmp.status == 1 ? 2 : 1;
    },
    resetTmp () {
      this.tmp.id = "";
      this.tmp.auditor = "";
      this.tmp.status = 1;
    },
    edit (row) {
      this.editModalVisible = true;
      this.editModalTitle = "修改审核用户";
      this.editModalOkText = "提交修改"
      this.tmp.id = row.id;
      this.tmp.status = row.status;
      this.tmp.auditor = row.auditor;
    },
    preSave () {
      let th = this;
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          th.saveAuditAdmin();
        }
      });
    },
    saveAuditAdmin () {
      this.tmp.type = this.type;
      if (!this.tmp.id) {
        addAuditAdmin(this.tmp)
          .then((res) => {})
          .catch((err) => {
            if (err.status == 201) {
              this.$message.success("操作成功");
              this.getTableData();
              this.editModalVisible = false;
            } else {
              if (err.data.msg) {
                this.$message.error(err.data.msg)
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            }
          });
      } else {
        editAuditAdmin(this.tmp)
          .then((res) => {})
          .catch((err) => {
            if (err.status == 201) {
              this.$message.success("操作成功");
              this.getTableData();
              this.editModalVisible = false;
            } else {
              if (err.data.msg) {
                this.$message.error(err.data.msg)
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            }
          });
      }
    },
    remove: function (id) {
      let th = this;
      this.$confirm({
        title: "操作提示",
        content: "删除之后不可恢复，您确认删除该数据？",
        onOk () {
          removeAuditAdmin(id)
            .then((res) => {})
            .catch((err) => {
              if (err.status == 201) {
                th.$message.success("操作成功");
                th.getTableData();
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            });
        },
        onCancel () { },
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.controlBox {
  display: flex;
  .btn {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
.e-space {
  margin-left: 10px;
}
</style>